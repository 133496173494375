import { PulseColorData } from 'components/pulse-color-picker/pulse-color-picker-types';
import { BookingEventColorType } from '../components/booking-types';
import RPBookingModel from '../models/rp-booking-model';
import PulseColorPickerData from 'components/pulse-color-picker/pulse-color-picker-colors.json';

export const getEventColor = (eventRecord: RPBookingModel): string => {
  let eventColor = BookingEventColorType.standard;

  switch (eventRecord.bookingStatus.value) {
    case 'workCompleted':
    case 'confirmed':
      if (eventRecord.priority === 'escalated') {
        eventColor = BookingEventColorType.escalated;
      }
      if (eventRecord?.job?.isNC) {
        eventColor = BookingEventColorType.nonCharge;
      }
      if (eventRecord.ptoRequestId) {
        eventColor = BookingEventColorType.pto;
      }
      break;
    case 'tentative':
      eventColor = BookingEventColorType.standardTentative;
      if (eventRecord.priority === 'escalated') {
        eventColor = BookingEventColorType.escalatedTentative;
      }
      if (eventRecord?.job?.isNC) {
        eventColor = BookingEventColorType.nonChargeTentative;
      }
      if (eventRecord.ptoRequestId) {
        eventColor = BookingEventColorType.ptoTentative;
      }
      break;
    case 'unapproved':
      eventColor = BookingEventColorType.unApproved;
      break;
    default:
      eventColor = BookingEventColorType.standard;
  }
  return eventColor;
};

const exceptedKeys = [
  'activityid',
  'jobid',
  'singleday',
  'daysbetween',
  'bookingid',
  'roleid',
  'departmentid',
  'reportedby',
  'color',
];

export const BOOKING_HISTORIES_GRID_JQ = `{
  data: [
      .included as $included |
      .data[] as $data |
      $data |
      .relationships["user"].data as $user |
      $data.attributes as $attributes |
      $attributes.old_values as $oldValue |
      $attributes.new_values as $newValue |
      $oldValue | length as $countOldValue |
      $newValue | length as $countNewValue |
      $oldValue | (if ($countOldValue != 0) 
                      then 
                          del( . ["created_by", "updated_by", 
                          "${exceptedKeys.join(`", "`)}"])
                      else
                          $oldValue 
                      end) as $oldValueFilterKey | length as $countOldValueFilterKey |
      $newValue |  (if ($countNewValue != 0) 
                      then 
                          del( . ["created_by", "updated_by", 
                          "${exceptedKeys.join(`", "`)}"])
                      else
                          $newValue 
                      end) as $newValueFilterKey | length as $countNewValueFilterKey |
      {
          userName: (
              if $user then $included[] |
              select(.type as $type |
                  .id as $id |
                  $user |
                  select(.id == $id and.type == $type)) |
              .attributes.name
              else
                  null
              end
          ),
          resourceId: $attributes.model_id,
          updateDate: $attributes.updated_at,
          optionChanged: [ (if ($countOldValueFilterKey < $countNewValueFilterKey) then $newValueFilterKey else $oldValueFilterKey end) | to_entries[] | .key],
          oldValue: [$oldValueFilterKey |
                  to_entries[] |
                  .value 
          ],
          newValue: [$newValueFilterKey | 
                            to_entries[] | 
                            .value 
          ],
          model: $attributes.model,
          event: $attributes.event,
      }
  ] | [.[] | select(. != null)],
  page: .meta.page
}`;

export const getEventColorCustom = (bookingColor?: PulseColorData, bookingStatus?: string): string => {
  const PULSE_GREEN = '#2ab0ae';
  let backgroundColor = bookingColor ? bookingColor?.colorCode : PULSE_GREEN;
  if (bookingStatus === 'tentative' && bookingColor) {
    switch (bookingColor.name) {
      case 'orange':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #ffd5a3 7.5px, #ffd5a3 20px)`;
        break;
      case 'yellow':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #ffdda1 7.5px, #ffdda1 20px)`;
        break;
      case 'lime':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #c6d4ab 7.5px, #c6d4ab 20px)`;
        break;
      case 'green':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #a9bfa8 7.5px, #a9bfa8 20px)`;
        break;
      case 'teal':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #77c1b7 7.5px, #77c1b7 20px)`;
        break;
      case 'cyan':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #a7e3ed 7.5px, #a7e3ed 20px)`;
        break;
      case 'blue':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #a7d3fc 7.5px, #a7d3fc 20px)`;
        break;
      case 'indigo':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #a9b4e2 7.5px, #a9b4e2 20px)`;
        break;
      case 'violet':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #bbabe3 7.5px, #bbabe3 20px)`;
        break;
      case 'purple':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #bbabe3 7.5px, #bbabe3 20px)`;
        break;
      case 'pink':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #fea7ba 7.5px, #fea7ba 20px)`;
        break;
      case 'red':
        backgroundColor = `repeating-linear-gradient(135deg, ${bookingColor.colorCode}, ${bookingColor.colorCode} 12px, #ffaea7 7.5px, #ffaea7 20px)`;
        break;
      default:
        break;
    }
  }
  return backgroundColor;
};
export const getPulseCustomColorData = (colorName: string): PulseColorData => {
  const pulseDefaultColor: PulseColorData = {
    name: 'blue',
    colorCode: '#42A5F5',
    default: true,
  };
  return PulseColorPickerData.find(color => color.name === colorName) ?? pulseDefaultColor;
};
